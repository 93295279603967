import React from 'react';
// import {FooterController} from '../components/Footer';
// import {HeaderController} from '../components/Header';
import {Custom404Content} from '../components/Custom404Content';
import {HeaderView} from '../components/Header/HeaderView';

import {changeLanguage} from '../lib/context/actionCreators';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {Action} from '../lib/context/types';
import {useTranslation} from 'react-i18next';
import {FooterView} from '../components/Footer';

const Custom404 = () => {
  const {t} = useTranslation();
  const dispatch: Dispatch<Action> = useDispatch();
  const handleLanguageSelect = (value: string) => {
    dispatch(changeLanguage(value));
  };

  return (
    <>
      <HeaderView
        handleLanguageSelect={handleLanguageSelect}
        lazyLoading={false}
        title={t('404.page_title')}
      />

      <Custom404Content />

      <FooterView
        lazyLoading={false}
        title=""
        subTitle=""
        appUrls={{ios: '', android: ''}}
        contact={[]}
        links={[]}
        usps={[]}
        minimalistic={true}
      />
    </>
  );
};

export default Custom404;
